<template>
  <div class="flex flex-nowrap justify-between">
    <a
      v-for="item in socials"
      :key="`social-${item.id}`"
      :href="item.link"
      :title="item.title"
      target="_blank"
      rel="noopener noreferrer"
      class="mr-3 text-white-100 last:mr-0 hover:text-new-red-dark 2xl:mr-6"
    >
      <SvgIcon :name="item.icon" class="icons-medium size-6" />
    </a>
    <BaseTheFeedbackButton class="cursor-pointer" />
  </div>
</template>

<script setup>
const socials = [
  // { id: 1, icon: 'square-socials/zen', title: 'Яндекс Дзен', link: '/' },
  // { id: 2, icon: 'square-socials/feed', title: 'RSS-лента', link: '/' },
  {
    id: 9,
    icon: 'square-socials/telegram-n',
    title: 'Telegram',
    link: 'https://t.me/rf_history',
  },
  {
    id: 7,
    icon: 'square-socials/vk-n',
    title: 'ВКонтакте',
    link: 'https://vk.com/histrfvk',
  },
  {
    id: 12,
    icon: 'square-socials/vkp',
    title: 'ВК Видео',
    link: 'https://vkvideo.ru/@histrfvk',
  },
  {
    id: 11,
    icon: 'square-socials/rt',
    title: 'RuTube',
    link: 'https://rutube.ru/channel/23517827/',
  },
  {
    id: 3,
    icon: 'square-socials/youtube-n',
    title: 'YouTube',
    link: 'https://www.youtube.com/channel/UCJf5zN7YGOYF3IacL9MqJAg',
  },
  {
    id: 8,
    icon: 'square-socials/ok-n',
    title: 'Одноклассники',
    link: 'https://ok.ru/historyrf',
  },
  {
    id: 10,
    icon: 'square-socials/dzen-n',
    title: 'Yandex Dzen',
    link: 'https://dzen.ru/histrf',
  },

  // {
  //   id: 4,
  //   icon: 'square-socials/twitter',
  //   title: 'Twitter',
  //   link: 'https://twitter.com/history_RF',
  // },
  // {
  //   id: 5,
  //   icon: 'square-socials/instagramm',
  //   title: 'Instagramm',
  //   link: 'https://www.instagram.com/histrf_ru/',
  // },
  // {
  //   id: 6,
  //   icon: 'square-socials/facebook',
  //   title: 'Facebook',
  //   link: 'https://www.facebook.com/histrf.ru',
  // },
];
</script>

<style lang="scss"></style>
