<template>
  <div
    class="transition-effect flex cursor-pointer items-center justify-center"
    :class="getClasses"
    @click="handleClick"
  >
    <SvgIcon
      v-if="icon && iconPosition === 'left'"
      :name="icon"
      :class="{ 'mr-3': !iconOnly && text }"
    />
    <span
      v-if="text && !iconOnly"
      class="text-smaller-caps whitespace-nowrap font-bold"
      >{{ text }}</span
    >
    <SvgIcon
      v-if="icon && iconPosition === 'right'"
      :name="icon"
      :class="{ 'ml-3': !iconOnly && text }"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  icon: {
    type: String,
    default: '',
  },

  // флаг, что выводится только иконка, даже если передан текст
  iconOnly: {
    type: Boolean,
    default: false,
  },

  text: {
    type: String,
    default: '',
  },

  isSmall: {
    type: Boolean,
    default: false,
  },

  // флаг, ведет ли ссылка за пределы сайта
  isExternal: {
    type: Boolean,
    default: false,
  },

  widthMin: {
    type: Boolean,
    default: false,
  },

  href: {
    type: String,
    default: '',
  },

  iconPosition: {
    type: String,
    default: 'right',
    validate: (value) => ['right', 'left'].includes(value),
  },

  type: {
    type: String,
    default: 'default',
    validate: (value) =>
      [
        'default, inverse, inverse--dark-border, inverse--red-border, secondary',
      ].includes(value),
  },
});

const router = useRouter();

const getClasses = computed(() => {
  let res = '';

  res += props.isSmall ? 'p-2 ' : 'p-4 ';

  if (props.text && !props.iconOnly) res += 'w-full ';

  switch (props.type) {
    case 'default':
      res += 'button__default ';
      break;
    case 'inverse':
      res += 'button__inverse ';
      break;
    case 'inverse--dark-border':
      res += 'button__inverse--dark-border ';
      break;
    case 'inverse--red-border':
      res += 'button__inverse--red-border ';
      break;
    case 'secondary':
      res += 'button__secondary ';
      break;
    default:
      res += 'button__default ';
      break;
  }

  // if (this.iconOnly) res += 'rounded-50%'

  if (props.widthMin) res += 'w-min';

  return res;
});

const handleClick = () => {
  if (props.isExternal) {
    window.open(props.href);
  } else {
    router.push(props.href);
  }
};
</script>

<style lang="scss">
.button {
  &__default {
    @apply text-black-100 md:hover:text-white-100 bg-white-100 md:hover:bg-ruby-red-100;
    @apply transition-all ease-in-out duration-300;
    @apply border border-black-16;
  }

  &__inverse,
  &__inverse--dark-border,
  &__inverse--red-border {
    @apply transition-all ease-in-out duration-300;
    @apply border border-white-100;
    @apply md:hover:text-black-100 md:hover:bg-white-100;
  }
  &__inverse--dark-border {
    @apply border-black-16;
  }
  &__inverse--red-border {
    @apply border-new-red-dark;
  }

  &__secondary {
    @apply text-white-100;
    @apply border border-black-16;
    @apply bg-ruby-red-100 md:hover:bg-white-100 md:hover:text-black-100;
  }
}
</style>
